import React, { useState, useMemo, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import Quill from 'quill';
import ImageUploader from 'quill-image-uploader';
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import moment from "moment";
import './Write.scss'
import './quill.css'
import { Base_URL } from "../../../constant/Constant";
import { toast } from "react-toastify";

Quill.register('modules/imageUploader', ImageUploader);

const AdminWrite = () => {
  const state = useLocation().state;
  
  const [value, setValue] = useState(state?.desc || '');
  const [title, setTitle] = useState(state?.title || '');
  const [cat, setCat] = useState(state?.cat || '');
  const [file, setFile] = useState(state?.img || '');
  const [quill, setQuill] = useState(null);
  const quillRef = useRef();
  const [featured, setFeatured] = useState(false);
  const navigate = useNavigate();
  console.log(state,file)
  axios.defaults.baseURL = `${Base_URL}/api`;

  const upload = async (files) => {
    try {
      const formData = new FormData();
      files.forEach((file) => {
        if (file.size <= 100 * 1024 * 1024) {
          formData.append("image", file);
        } else {
          console.log(`File ${file.name} is larger than 100MB and will not be uploaded.`);
        }
      });
  
      const res = await axios.post(`/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      console.log('Response from /upload:', res.data); // Add this line
  
      return res.data;
    } catch (err) {
      console.error('Error uploading images:', err);
    //  
    }
  };
  

  const handleClick = async (e) => {
    e.preventDefault();
  
    if (quillRef.current) {
      const quillEditor = quillRef.current.getEditor();
      const contents = quillEditor.getContents();
     console.log('contentssss',contents)
      const images = contents.ops
        .filter(op => op.insert && op.insert.image)
        .map(op => op.insert.image);
  
      console.log('Images:', images);
  
      try {
        const imgUrls = await upload(images);
  
        console.log('Uploaded image URLs:', imgUrls);
  
        // Replace image placeholders with actual URLs in the HTML content
        const descWithImages = contents.ops.reduce((acc, op) => {
          if (op.insert && op.insert.image) {
            return acc + `<img src="${images.shift()}" alt="uploaded image" />`;
          }
          return acc + op.insert;
        }, '');
  
        const dataToSend = {
          title,
          desc: value,
          cat,
          img: file ? await upload([file]) : '',
          images, // Use the uploaded image URLs
          date: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
          featured,
        };
  
        console.log('Data sent to backend:', dataToSend);
  
        let response;
        if (state?.id) {
          response = await axios.put(`/posts/${state.id}`, dataToSend);
          toast.success(' Blog updated successfully !')
        } else {
          response = await axios.post('/posts', dataToSend);
          toast.success('Blog added successfully !')
        }
  
        console.log('Response data:', response.data);
        navigate('/update2/1');
      } catch (err) {
        console.error('Error:', err);
        console.error('Error response data:', err.response);
      }
    } else {
      console.error('Quill editor or its reference is not available.');
    }
  };
  
  
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],    
        ['image']
      ],
    },
    imageUploader: {
      upload: file => {
        console.log(`You chose ${file.name}`); // Log the file name
  
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append('image', file);
  
          axios.post('/upload', formData)
          .then(response => {
            console.log('Response from /upload:', response.data); // Log the response data
            if (response.data[0]) { // Check if the first element exists
              resolve(response.data[0]); // Resolve with the first URL
            } else {
              console.error('No URL in the response');
              reject('Upload failed');
            }
          })
          .catch(error => {
            console.error('Upload failed:', error);
            reject('Upload failed');
          });
          
        });
      },
    },
  }), []); // Add this line
  

  return (
    <>
      <div className="write-back-button" style={{
        display: 'flex',
        justifyContent: 'flex-start',
        margin: '1em 3em',

      }}>
        <Link to='/update2/1'>
          <button style={{ fontSize: '1.2rem', padding: '5px 30px', borderRadius: '4px', cursor: 'pointer' }}>Back</button>
        </Link>
      </div>

      <div className="write-blog-wrapper" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>

        <div className="add">
          <div className="content">
            <input
              type="text"
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <div className="editorContainer">
              <ReactQuill
                ref={(ref) => {
                  if (ref && !quillRef.current) {
                    quillRef.current = ref;
                    setQuill(ref);
                  }
                }}
                className="editor"
                theme="snow"
                value={value}
                onChange={setValue}
                modules={modules}
              />


            </div>
          </div>
          <div className="menu">
            <div className="item" style={{ lineHeight: '1.9rem' }}>
              <h1 style={{ fontSize: '1.5rem', color: 'whitesmoke' }}>Publish Blog</h1>
              <span style={{ color: 'whitesmoke', fontSize: '1rem' }}>
                <b>Status: </b> Draft
              </span>

              <input
                style={{ display: "none" }}
                type="file"
                id="file"
                name="image"
                onChange={(e) => setFile(e.target.files[0])}
              />
              <label className="file" htmlFor="file" style={{ color: 'whitesmoke', fontSize: '1.2rem' }}>
                Upload Thumb
              </label>
              {file && (
                <div>
                  <img
                    src={URL.createObjectURL(file)}
                    alt="Selected"
                    style={{ marginTop: '10px', width: '100%', height: 'auto' }}
                  />
                </div>
              )}
              <div className="buttons">

                <button onClick={handleClick} style={{ fontSize: '1.3rem', margin: '15px 0', padding: '5px 25px', borderRadius: '4px' }}>Publish Blog</button>
              </div>
            </div>
            <div className="item">
              <h1 style={{ fontSize: '1.3rem', color: 'whitesmoke' }}>Category</h1>
              <div className="cat">
                <input
                  type="radio"
                  checked={cat === "money transfers"}
                  name="cat"
                  value="money transfers"
                  id="money transfers"
                  onChange={(e) => setCat(e.target.value)}
                />
                <label htmlFor="money transfers">Money transfers</label>
              </div>
              <div className="cat">
                <input
                  type="radio"
                  checked={cat === "personal finance"}
                  name="cat"
                  value="personal finance"
                  id="personal finance"
                  onChange={(e) => setCat(e.target.value)}
                />
                <label htmlFor="personal finance">Personal finance</label>
              </div>
              <div className="cat">
                <input
                  type="radio"
                  checked={cat === "digital currencies"}
                  name="cat"
                  value="digital currencies"
                  id="digital currencies"
                  onChange={(e) => setCat(e.target.value)}
                />
                <label htmlFor="digital currencies">Digital Currencies</label>
              </div>
              <div className="cat">
                <input
                  type="radio"
                  checked={cat === "Life style & culture"}
                  name="cat"
                  value="Life style & culture"
                  id="Life style & culture"
                  onChange={(e) => setCat(e.target.value)}
                />
                <label htmlFor="Life style & culture">Life style & culture</label>
              </div>
              <div className="cat">
                <input
                  type="radio"
                  checked={cat === "tagocash community"}
                  name="cat"
                  value="tagocash community"
                  id="tagocash community"
                  onChange={(e) => setCat(e.target.value)}
                />
                <label htmlFor="tagocash community">Tagocash Community</label>
              </div>
              <div className="cat">
                <input
                  type="radio"
                  checked={cat === "world currencies"}
                  name="cat"
                  value="world currencies"
                  id="world currencies"
                  onChange={(e) => setCat(e.target.value)}
                />
                <label htmlFor="world currencies">World Currencies</label>
              </div>
            </div>
            <div className="item">
              <h1 style={{ fontSize: '1.3rem', color: 'whitesmoke' }}>Featured</h1>
              <div className="featured">
                <input
                  type="checkbox"
                  checked={featured}
                  id="featured"
                  onChange={(e) => setFeatured(e.target.checked)}
                />
                <label htmlFor="featured" style={{ fontSize: '0.8rem', color: 'whitesmoke' }}>Set as Featured</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminWrite;
