import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/Header';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Base_URL } from '../../constant/Constant';

axios.defaults.withCredentials = true;

const Form = () => {
  const isNonMobile = useMediaQuery('(min-width:600px');
  const navigate = useNavigate();
  useEffect(() => {
    axios.get(`${Base_URL}/form`).then((res) => {
      if (res.data.Status === 'Success') {
        if (res.data.role === 'superadmin') {
          navigate('/form');
        } else {
          // Redirect others to a page with a "You are not authorized" message
          navigate('/unauthorized');
        }
      } else {
        navigate('/login');
      }
    });
  }, [navigate]);


  const [data, setData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    reenteredPassword: '',
    country: '',
    city: '',
    streetaddress: '',
    zipcode: '',
    role: '',
  });

  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [emptyFields, setEmptyFields] = useState([]);

  const notifySuccess = () =>
    toast.success('Employee Added Successfully!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

  const notifyError = (message) => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

    // Find the empty fields when showing error message
    const emptyFields = Object.keys(data).filter((field) => !data[field]);
    setEmptyFields(emptyFields);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if passwords match
    if (data.password !== data.reenteredPassword) {
      setPasswordsMatch(false);
      notifyError('Passwords do not match.');
      return;
    }

    // Check if any required fields are empty
    const requiredFields = ['firstname', 'lastname', 'email', 'password', 'reenteredPassword', 'country', 'city', 'streetaddress', 'zipcode', 'role'];
    const emptyFields = requiredFields.filter((field) => !data[field]);

    if (emptyFields.length > 0) {
      notifyError('Please fill in all required fields.');
      setEmptyFields(emptyFields);
      return;
    }

    axios
      .post(`${Base_URL}/create`, data)
      .then((res) => {
        navigate('/employees');
        notifySuccess();
      })
      .catch((err) => console.log(err));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear the "Passwords don't match" error message when inputs change
    if (name === 'password' || name === 'reenteredPassword') {
      setPasswordsMatch(true);
    }
  };

  return (
    <Box m="20px">
      <Header title="CREATE EMPLOYEE" subtitle="Create a New Employee" />

      <form onSubmit={handleSubmit}>
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="First Name"
            name="firstname"
            sx={{
              gridColumn: 'span 2',
              borderColor: emptyFields.includes('firstname') ? 'red' : '',
              '& .css-1m1f1hj-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
                color: "white",
              },
            }}
            autoComplete='off'
            onChange={handleInputChange}

          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Last Name"
            name="lastname"
            sx={{
              gridColumn: 'span 2',
              borderColor: emptyFields.includes('lastname') ? 'red' : '',
              '& .css-1m1f1hj-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
                color: "white",
              },
            }}
            autoComplete='off'
            onChange={handleInputChange}

          />
          <TextField
            fullWidth
            variant="filled"
            type="email"
            label="Email"
            name="email"
            sx={{
              gridColumn: 'span 4',
              borderColor: emptyFields.includes('email') ? 'red' : '',
              '& .css-1m1f1hj-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
                color: "white",
              },
            }}
            autoComplete='off'
            onChange={handleInputChange}

          />
          <TextField
            fullWidth
            variant="filled"
            type="password"
            label="Password"
            name="password"
            sx={{
              gridColumn: 'span 2',
              borderColor: emptyFields.includes('password') ? 'red' : '',
              '& .css-1m1f1hj-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
                color: "white",
              },
            }}
            autoComplete="off"
            onChange={handleInputChange}

          />
          <TextField
            fullWidth
            variant="filled"
            type="password"
            label="Re-enter Password"
            name="reenteredPassword"
            sx={{
              gridColumn: 'span 2',
              borderColor: emptyFields.includes('reenteredPassword') ? 'red' : '',
              '& .css-1m1f1hj-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
                color: "white",
              },
            }}
            autoComplete="off"
            onChange={handleInputChange}
            error={!passwordsMatch}
            helperText={!passwordsMatch && 'Passwords do not match'}

          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Country"
            name="country"
            sx={{
              gridColumn: 'span 2',
              borderColor: emptyFields.includes('country') ? 'red' : '',
              '& .css-1m1f1hj-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
                color: "white",
              },
            }}
            autoComplete='off'
            onChange={handleInputChange}

          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="City"
            name="city"
            sx={{
              gridColumn: 'span 2',
              borderColor: emptyFields.includes('city') ? 'red' : '',
              '& .css-1m1f1hj-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
                color: "white",
              },
            }}
            autoComplete='off'
            onChange={handleInputChange}

          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Street Address"
            name="streetaddress"
            sx={{
              gridColumn: 'span 2',
              borderColor: emptyFields.includes('streetaddress') ? 'red' : '',
              '& .css-1m1f1hj-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
                color: "white",
              },
            }}
            autoComplete='off'
            onChange={handleInputChange}

          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Zip Code"
            name="zipcode"
            sx={{
              gridColumn: 'span 2',
              borderColor: emptyFields.includes('zipcode') ? 'red' : '',
              '& .css-1m1f1hj-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
                color: "white",
              },
            }}
            autoComplete='off'
            onChange={handleInputChange}

          />
          <FormControl fullWidth variant="filled" sx={{
            gridColumn: 'span 4',
            borderColor: emptyFields.includes('role') ? 'red' : '',
            '& .css-1m1f1hj-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
              color: "white",
            },
          }}>
            <InputLabel>Access Level</InputLabel>
            <Select
              label="Access Level"
              name="role" // Use "access" instead of "accessLevel"
              value={data.role}
              onChange={handleInputChange}

            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="marketer">Marketer</MenuItem>
              <MenuItem value="Tech Staff">Tech Staff</MenuItem>
              <MenuItem value="blogger">Blogger</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box display="flex" justifyContent="end" mt="20px">
          <Button type="submit" color="secondary" variant="contained">
            Create New Employee
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default Form