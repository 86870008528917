import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    isSuccessful: false,
    error: null,
    user: null,
    token: null,
    isAuthenticated: false,
  },
  reducers: {
    onLoginRequest(state) {
      state.loading = true;
    },
    onLoginSuccess(state, action) {
      state.loading = false;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = true;
    },
    onLoginFailed(state) {
      state.loading = false;
    },
    onLogoutSuccess(state, action) {
      state.loading = false;
      state.isAuthenticated = false;
      localStorage.removeItem("persist:root");
      state.auth = "";
      state.token = "";
    },
  },
});

export const {
  onLoginRequest,
  onLoginSuccess,
  onLoginFailed,
  onLogoutSuccess,
  onUpdateBalance,
} = authSlice.actions;

export default authSlice.reducer;
