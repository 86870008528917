import { Box, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../axiosConfig";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import { Base_URL } from "../../constant/Constant";

const Cms = () => {
  const { token } = useSelector((state) => state.auth);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  useEffect(() => {}, [navigate]);

  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(`${Base_URL}/api/page`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setData(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.2 },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Link to={`/cms/${row.id}`}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          </Link>
        </>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="CMS" subtitle="Managing the CMS" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={data.map((val) => ({
            id: val.id,
            type:
              val.type === 1
                ? "Terms of Service"
                : val.type === 2
                ? "Privacy Policy"
                : "",
            date: val.date,
            title: val.title,
          }))}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default Cms;
