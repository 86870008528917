import {
    Box,
    Button,
    Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from 'react-toastify';
import { Base_URL } from "../../../constant/Constant";

const FaqPage = () => {

    var toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ];

    const module = {
        toolbar: toolbarOptions,
    };

    const isNonMobile = useMediaQuery("(min-width:600px)");
    const notify = () => toast.success('Successfully updated the website!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    const [book, setBook] = useState({
        desc1: "",
        desc2: "",
        desc3: "",
        desc4: "",
        desc5: "",
        desc6: "",
        desc7: "",
        desc8: "",
        desc9: "",
        desc10: "",
        desc11: "",
        desc12: "",
        desc13: "",
        desc14: "",
        desc15: "",
        desc16: "",
        desc17: "",
        desc18: "",
        desc19: "",
        desc20: "",
        desc21: "",
        desc22: "",
        desc23: "",
        desc24: "",
        desc25: "",
        desc26: "",
        par1: "",
        par2: "",
        par3: "",
        par4: "",
        par5: "",
        par6: "",
        par7: "",
        par8: "",
        par9: "",
        par10: "",
        par11: "",
        par12: "",
        par13: "",
        par14: "",
        par15: "",
        par16: "",
        par17: "",
        par18: "",
        par19: "",
        par20: "",
        par21: "",
        par22: "",
        par23: "",
        par24: "",
        par25: "",
        par26: "",
    });

    const [error, setError] = useState(false);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const location = useLocation();
    const navigate = useNavigate();

    const bookId = location.pathname.split("/")[2];

    const handleChange = (e) => {
        setBook((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };



    const getUserRole = () => {
        return "superadmin"; // Replace with actual role value
    };

    const handleClick = async (e) => {
        e.preventDefault();
    
        try {
            const userRole = getUserRole();
            let updateEndpoint = `/faq_page_pending/${bookId}`; // Default to pending updates
            let data = {
                desc1: book.desc1,
                desc2: book.desc2,
                desc3: book.desc3,
                desc4: book.desc4,
                desc5: book.desc5,
                desc6: book.desc6,
                desc7: book.desc7,
                desc8: book.desc8,
                desc9: book.desc9,
                desc10: book.desc10,
                desc11: book.desc11,
                desc12: book.desc12,
                desc13: book.desc13,
                desc14: book.desc14,
                desc15: book.desc15,
                desc16: book.desc16,
                desc17: book.desc17,
                desc18: book.desc18,
                desc19: book.desc19,
                desc20: book.desc20,
                desc21: book.desc21,
                desc22: book.desc22,
                desc23: book.desc23,
                desc24: book.desc24,
                desc25: book.desc25,
                desc26: book.desc26,
                par1: book.par1,
                par2: book.par2,
                par3: book.par3,
                par4: book.par4,
                par5: book.par5,
                par6: book.par6,
                par7: book.par7,
                par8: book.par8,
                par9: book.par9,
                par10: book.par10,
                par11: book.par11,
                par12: book.par12,
                par13: book.par13,
                par14: book.par14,
                par15: book.par15,
                par16: book.par16,
                par17: book.par17,
                par18: book.par18,
                par19: book.par19,
                par20: book.par20,
                par21: book.par21,
                par22: book.par22,
                par23: book.par23,
                par24: book.par24,
                par25: book.par25,
                par26: book.par26,
                status: null // Default status is null
            };
    
            if (userRole === "superadmin" || userRole === "admin") {
                const status = "approved";
                data.status = status; // Update status for admin users
                updateEndpoint = `/faq_page/${bookId}`; // Use the direct update endpoint
            }
    
            const response = await axios.put(`${Base_URL}${updateEndpoint}`, data);
            console.log(response.data, "data----->");
            navigate("/edit");
        } catch (err) {
            console.error(err);
            setError(true);
        }
    };
    
    useEffect(() => {
        axios
            .get(`${Base_URL}/faq_page/${bookId}`)

            .then((response) => {
                const existingBook = response.data[0];
                setBook(existingBook);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [bookId]);

    return (
        <Box m="20px" sx={{
            "& > div": {
                paddingBottom: "30px",
            },
        }}>
            <Header title="UPDATE WEBSITE" />

            {/* FAQ SECTION START */}
            <Box
                display="grid"
                gap="25px"
                gridTemplateColumns="repeat(1, minmax(0, 1fr))"

                sx={{
                    "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 1",
                        marginBottom: "30px",
                    },
                }}
            >

                <Header title="FAQ Page"
                    subtitle="Edit FAQ Page content" />
            </Box>
            <Box
                display="grid"
                gap={isMobile ? "70px" : "25px"}
                gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                sx={{
                    "& > div": {
                        gridColumn: isMobile ? "span 1" : undefined,
                        marginBottom: isMobile ? "60px" : "40px",
                    },
                }}
            >
                <ReactQuill
                    theme="snow"
                    value={book.desc1}
                    modules={module}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc1: value }))
                    }
                />
                <ReactQuill
                    value={book.par1}
                    modules={module}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par1: value }))
                    }
                />
                <ReactQuill
                    value={book.desc2}
                    modules={module}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc2: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par2}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par2: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc3}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc3: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par3}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par3: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc4}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc4: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par4}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par4: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc5}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc5: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par5}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par5: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc6}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc6: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par6}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par6: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc7}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc7: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par7}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par7: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc8}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc8: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par8}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par8: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc9}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc9: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par9}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par9: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc10}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc10: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par10}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par10: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc11}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc11: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par11}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par11: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc12}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc12: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par12}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par12: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc13}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc13: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par13}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par13: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc14}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc14: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par14}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par14: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc15}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc15: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par15}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par15: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc16}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc16: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par16}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par16: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc17}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc17: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par17}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par17: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc18}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc18: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par18}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par18: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc19}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc19: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par19}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par19: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc20}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc20: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par20}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par20: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc21}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc21: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par21}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par21: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc22}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc22: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par22}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par22: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc23}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc23: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par23}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par23: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc24}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc24: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par24}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par24: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc25}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc25: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par25}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par25: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc26}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc26: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.par26}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, par26: value }))
                    }
                />

            </Box>

            <Box my={2}>
                <Button variant="contained" color="secondary" onClick={(e) => { notify(); handleClick(e); }}>
                    Update
                </Button>



            </Box>
            {error && <Typography color="error">Something went wrong!</Typography>}
        </Box>
    );
};

export default FaqPage;
