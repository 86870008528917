import {
    Box,
    Button,
    Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from 'react-toastify';
import { Base_URL } from "../../../constant/Constant";

const ContactPage = () => {

    var toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ];

    const module = {
        toolbar: toolbarOptions,
    };

    const isNonMobile = useMediaQuery("(min-width:600px)");
    const notify = () => toast.success('Successfully updated the website!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    const [book, setBook] = useState({
        title: "",
        desc: "",
    });
    console.log(book, "book======>")
    const [error, setError] = useState(false);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const location = useLocation();
    const navigate = useNavigate();

    const bookId = location.pathname.split("/")[2];

    const handleChange = (e) => {
        setBook((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const getUserRole = () => {
        return "superadmin"; // Replace with actual role value
    };

    const handleClick = async (e) => {
        e.preventDefault();
        try {
            const userRole = getUserRole();
            let updateEndpoint = `/contact_page_pending/${bookId}`; // Default to pending updates
            let data = {
                "title": book.title,
                "desc": book.desc,
                "status": null // Default status is null
            };
    
            if (userRole === "superadmin" || userRole === "admin") {
                const status = "approved";
                data.status = status; // Update status for admin users
                updateEndpoint = `/contact_page/${bookId}`; // Use the direct update endpoint
            }
    
            const response = await axios.put(`${Base_URL}${updateEndpoint}`, data);
            console.log(response.data, "data----->");
            navigate("/edit");
        } catch (err) {
            console.error(err);
            setError(true);
        }
    };
    

    useEffect(() => {
        axios
            .get(`${Base_URL}/contact_page/${bookId}`)

            .then((response) => {
                const existingBook = response.data[0];
                setBook(existingBook);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [bookId]);


    return (
        <Box m="20px" sx={{
            "& > div": {
                paddingBottom: "30px",
            },
        }}>
            <Header title="UPDATE WEBSITE" />

            {/* CONTACT SECTION START */}
            <Box
                display="grid"
                gap="25px"
                gridTemplateColumns="repeat(1, minmax(0, 1fr))"

                sx={{
                    "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 1",
                        marginBottom: "30px",
                    },
                }}
            >

                <Header title="Contact Page"
                    subtitle="Edit Contact Page content" />
            </Box>
            <Box
                display="grid"
                gap={isMobile ? "70px" : "25px"}
                gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                sx={{
                    "& > div": {
                        gridColumn: isMobile ? "span 1" : undefined,
                        marginBottom: isMobile ? "60px" : "40px",
                    },
                }}
            >
                <ReactQuill
                    theme="snow"
                    value={book?.title}
                    modules={module}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, title: value }))
                    }
                />
                <ReactQuill
                    value={book?.desc}
                    modules={module}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc: value }))
                    }
                />

            </Box>

            <Box my={2}>
                <Button variant="contained" color="secondary" onClick={(e) => { notify(); handleClick(e); }}>
                    Update
                </Button>
            </Box>
            {error && <Typography color="error">Something went wrong!</Typography>}
        </Box>
    );
};

export default ContactPage;
