import React, {useState} from 'react';
import { Container, Typography, Button, Paper, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { tokens } from './theme';
import Grid from '@mui/material/Grid';
import Logo from './images/acxess.png'

const UnauthorizedPage = () => {
  const theme = useTheme();
  const [error, setError] = useState('');
  const colors = tokens(theme.palette.mode);
  return (
    <Container maxWidth="xs" style={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2, textAlign: 'center', background: "#1F2A40", lineHeight: "5rem" }}>
        <img src={Logo} alt="accessdenied_logo" width='95' />
        <Typography variant="h1" color="error" gutterBottom>
          ACCESS DENIED !!!
        </Typography>
        <Typography variant="h3" paragraph>
         You are not authorized to access this page.
       </Typography>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <Button variant="contained" component={Link} to="/"  color="primary" fullWidth sx={{ fontSize: '18px', height: '48px', borderRadius: '8px' }}>
                GO BACK HOME
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body3" color="textSecondary">
                Contact The Super Admin for Permission.
              </Typography>
            </Grid>
          </Grid>
      </Paper>
    </Container>
  );
};

export default UnauthorizedPage;
