import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Topbar from "../scenes/global/Topbar";
import Sidebar from "../scenes/global/Sidebar";
import axios from "../axiosConfig";
import { useSelector } from "react-redux";
import { Base_URL } from "../constant/Constant";

const MainPage = () => {
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(`${Base_URL}/api/page/checking-token`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
  };

  const [isSidebar, setIsSidebar] = useState(true);
  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={isSidebar} />
        <Outlet />
      </main>
    </div>

  );
};
export default MainPage;
