import { useState, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { ProtectedRoute, MainPage } from "./Routes";
import Dashboard from "./Dashboard";
import Employees from "./scenes/employees";
import Edit from "./scenes/edit";
import Cookies from "./scenes/cookies";
import Visitors from "./scenes/visitors";
import Approve from "./scenes/approve";
import Bar from "./scenes/bar";
import Heropage from "./scenes/updates/heropage";
import SignUppage from "./scenes/updates/signup";
import ContactPage from "./scenes/updates/contact";
import TagoWallet from "./scenes/updates/tagowallet";
import FaqPage from "./scenes/updates/faqpage";
import Write from "./scenes/write/Write";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import EmployeeDetail from './EmployeeDetail'
import Blogger from './scenes/blogger'
import EditEmployee from './EditEmployee'
import Login from './Login'
import Profile from './Profile'
import UnauthorizedPage from './UnauthorizedPage';
import AdminBlog from "./scenes/updates/adminblog";
import AdminWrite from "./scenes/updates/write/Write";
import Subscribers from "./scenes/subscribers";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CmsUpdate from "./scenes/Cms/CmsUpdate";
import Cms from "./scenes/Cms/Page";


function App() {
  const [theme, colorMode] = useMode();
  // const [isSidebar, setIsSidebar] = useState(true);
  // const location = useLocation();
  // const isLoginPage = location.pathname === "/login";
  // const isWrite = location.pathname === "/write";
  // const isUnauthorised = location.pathname === "/unauthorized";
  // const isBlogger = /^\/blogger\//.test(location.pathname);
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const navigate = useNavigate();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ToastContainer />
      <ThemeProvider theme={theme}>
        <CssBaseline />
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <MainPage />
                  </ProtectedRoute>
                }
              >
                <Route path="/cms" element={<Cms />} />
                <Route path="/cms/:id" element={<CmsUpdate />} />
                <Route path="/blogger/:id" element={<Blogger />} />
                <Route path="/" element={<Dashboard />} />
                <Route path="/employees" element={<Employees />} />
                <Route path="/cookies" element={<Cookies />} />
                <Route path="/visitors" element={<Visitors />} />
                <Route path="/approve" element={<Approve />} />
                <Route path="/edit" element={<Edit />} />
                <Route path="/form" element={<Form />} />
                <Route path="/bar" element={<Bar />} />
                <Route path="/write" element={<Write />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/line" element={<Line />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/update1/:id" element={< Heropage />} />
                <Route path="/update2/:id" element={< AdminBlog />} />
                <Route path="/update3/:id" element={< ContactPage />} />
                <Route path="/update4/:id" element={< FaqPage />} />
                <Route path="/update5/:id" element={< SignUppage />} />
                <Route path="/update6/:id" element={< TagoWallet />} />
                <Route path="/update7/:id" element={< CmsUpdate />} />
                <Route path="/update8/:id" element={< CmsUpdate />} />
                <Route path="/adminwrite" element={< AdminWrite />} />
                <Route path="/subscribers" element={<Subscribers />} />
                <Route path="/geography" element={<Geography />} />
                <Route path="/unauthorized" element={<UnauthorizedPage />} />
                <Route path="/employeedetail/:id" element={<EmployeeDetail role="employee" />} />
                <Route path="/employeeEdit/:id" element={<EditEmployee />} />
              </Route>
              <Route path="/login" element={<Login />} />
            </Routes>
         
      </ThemeProvider>
    </ColorModeContext.Provider >
  );
}

export default App;
