import { Box, Typography, useTheme, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Header from "../../components/Header";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Base_URL } from "../../constant/Constant";
axios.defaults.withCredentials = true;
const ApprovalPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [pendingContent, setPendingContent] = useState([]);
  const [section, setSection] = useState("home_page"); // Default to home_page
  const navigate = useNavigate();
  const notify = () => toast.success('Approved Successfully!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyInfo = () => toast.info('You rejected the content!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  // Function to fetch pending content data from your server for a specific section
  const fetchPendingContent = async () => {
    try {
      const response = await axios.get(`${Base_URL}/pending-${section}`);
      if (response.status === 200) {
        setPendingContent(response.data);
      } else {
        console.error('Error fetching pending content');
      }
    } catch (error) {
      console.error('Error fetching pending content:', error);
    }
  };

  useEffect(() => {
    axios.get(`${Base_URL}/approve`).then((res) => {
      console.log(res, "res++++++")
      if (res.data.Status === 'Success') {
        if (res.data.role === 'superadmin') {
          navigate('/approve');
        } else {
          // Redirect others to a page with a "You are not authorized" message
          navigate('/unauthorized');
        }
      } else {
        navigate('/login');
      }
    });
  }, [navigate]);

  useEffect(() => {
    fetchPendingContent();
  }, [section]);

  // Function to approve a content item for a specific section
  const approveContent = async (id) => {
    try {
      const response = await axios.put(`${Base_URL}/approve-${section}/${id}`);
      if (response.status === 200) {
        fetchPendingContent(); // Fetch updated pending content
      } else {
        console.error('Error approving content');
      }
    } catch (error) {
      console.error('Error approving content:', error);
    }
  };

  // Function to reject a content item for a specific section
  const rejectContent = async (id) => {
    try {
      const response = await axios.put(`${Base_URL}/reject-${section}/${id}`);
      if (response.status === 200) {
        fetchPendingContent(); // Fetch updated pending content
      } else {
        console.error('Error rejecting content');
      }
    } catch (error) {
      console.error('Error rejecting content:', error);
    }
  };

  const columns = [
    { field: "id", flex: 0.3, headerName: "ID" },
    {
      field: "title",
      headerName: "Title",
      flex: 0.6,
      cellClassName: "name-column--cell",
    },
    {
      field: "desc1",
      headerName: "Desc1",
      flex: 0.6,
    },
    {
      field: "desc2",
      headerName: "Desc2",
      flex: 0.6,
    },
    {
      field: "desc3",
      headerName: "Desc3",
      flex: 0.6,
    },
    {
      field: "par",
      headerName: "Par",
      flex: 0.6,
    },
    {
      field: "par1",
      headerName: "Par1",
      flex: 0.6,
    },
    {
      field: "par2",
      headerName: "Par2",
      flex: 0.6,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.6,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1.3,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Button variant="contained" color="primary" onClick={() => { notify(); approveContent(row.id); }}>Approve</Button>
          <Button variant="contained" color="secondary" onClick={() => { notifyInfo(); rejectContent(row.id); }}>Reject</Button>
        </>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="CONTENT APPROVAL PAGE" subtitle="Pending content approval" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={pendingContent.map(item => ({
            id: item.id,
            title: item.title,
            desc1: item.desc1,
            desc2: item.desc2,
            desc3: item.desc3,
            par: item.par,
            par1: item.par1,
            par2: item.par2,
            status: item.status
          }))}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default ApprovalPage;
