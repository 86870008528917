import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField } from '@mui/material';
import axios from 'axios';
import Header from '../src/components/Header';
import { toast } from 'react-toastify';
import { Base_URL } from './constant/Constant';
axios.defaults.withCredentials = true
function Profile() {

  const navigate = useNavigate();

  const [superAdminData, setSuperAdminData] = useState({
    email: '',
    password: '',
  });
  const [isFocused, setIsFocused] = useState(false);
  const notify = () => toast.success('Profile Updated Successfully!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  useEffect(() => {
    axios.get(`${Base_URL}/profile`).then((res) => {
      if (res.data.Status === 'Success') {
        if (res.data.role === 'superadmin') {
          navigate('/profile');
        } else {
          // Redirect others to a page with a "You are not authorized" message
          navigate('/unauthorized');
        }
      } else {
        navigate('/login');
      }
    });
  }, [navigate]);
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`${Base_URL}/superadmin/${id}`)

      .then((res) => {
        setSuperAdminData({
          email: res.data[0].email,
          password: res.data[0].password,
        });
      })
      .catch((err) => console.log(err));
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    axios

      .put(`${Base_URL}/updatesuperadmin/${id}`, superAdminData)

      .then((res) => {
        if (res.data.affectedRows > 0) {
          navigate('/login'); // Redirect to the dashboard after updating
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" pt={4}>
      <Header title="Update Super Admin Details" />
      <form onSubmit={handleSubmit} style={{ width: '50%' }}>
        <TextField
          fullWidth
          variant="outlined"
          label="Email"
          placeholder="Enter Email"
          autoComplete="off"
          value={superAdminData.email}
          onChange={(e) =>
            setSuperAdminData({ ...superAdminData, email: e.target.value })
          }
          margin="normal"
          sx={{
            '& .css-wied2b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
              color: isFocused ? "white" : "white",
            },
            '& .css-wxl1jl-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: isFocused ? "rgba(255, 255, 255, 0.23)" : "rgba(255, 255, 255, 0.23)",
            },
          }}
          onFocus={() => setIsFocused(true)}
        />
        <TextField
          fullWidth
          variant="outlined"
          label="Password"
          placeholder="Enter Password"
          autoComplete="off"
          type="password"
          value={superAdminData.password}
          onChange={(e) =>
            setSuperAdminData({ ...superAdminData, password: e.target.value })
          }
          margin="normal"
          sx={{
            '& .css-wied2b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
              color: isFocused ? "white" : "white",
            },
            '& .css-wxl1jl-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: isFocused ? "rgba(255, 255, 255, 0.23)" : "rgba(255, 255, 255, 0.23)",
            },
          }}
          onFocus={() => setIsFocused(true)}
        />
        <Box mt={2}>
          <Button type="submit" variant="contained" style={{background: "#23A6F0"}} onClick={notify}>
            Update
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default Profile
