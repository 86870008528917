import React, { useEffect, useState, } from 'react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { tokens } from './theme';
import { mockTransactions } from './data/mockData';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EmailIcon from '@mui/icons-material/Email';
import CookieIcon from '@mui/icons-material/Cookie';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TrafficIcon from '@mui/icons-material/Traffic';
import Header from './components/Header';
import LineChart from './components/LineChart';
import GeographyChart from './components/GeographyChart';
import BarChart from './components/BarChart';
import StatBox from './components/StatBox';
import ProgressCircle from './components/ProgressCircle';


function EmployeeDetail({ role }) {
  const theme = useTheme();
  const { id } = useParams();
  const [employee, setEmployee] = useState([])
  const [employeeCount, setEmployeeCount] = useState(0);
  const [acceptedCookieCount, setAcceptedCookieCount] = useState(0);
  const [acceptedCookiePercentage, setAcceptedCookiePercentage] = useState(0);

  const [authorizedUsersPercentage, setAuthorizedUsersPercentage] = useState(0);
  const [visitorCount, setVisitorCount] = useState(0);
  const [visitorPercentage, setVisitorPercentage] = useState(0);
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  axios.defaults.withCredentials = true;
  const [employeeRole, setEmployeeRole] = useState('');

  useEffect(() => {
    // Fetch the employee's role based on their ID from the URL
    axios.get(`https://backend.tagocash.com/getEmployeeRole/${id}`)
      .then((res) => {
        const role = res.data.role;
        // Set the role in your component state
        setEmployeeRole(role);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [id]);

  useEffect(() => {
    axios.get('https://backend.tagocash.com/get/' + id)
      .then(res => setEmployee(res.data.Result[0]))
      .catch(err => console.log(err));
  })
  const handleLogout = () => {
    axios.get('https://backend.tagocash.com/logout')
      .then(res => {
        navigate('/login')
      }).catch(err => console.log(err));
  }



  const fetchAcceptedCookieCount = () => {
    axios.get('https://backend.tagocash.com/get-cookie-count')
      .then(res => {
        setAcceptedCookieCount(res.data.count);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    fetchAcceptedCookieCount();
    // Replace the setInterval time with the interval you want to fetch the data (e.g., every 1 minute)
    const interval = setInterval(fetchAcceptedCookieCount, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Calculate the percentage
    const totalPossibleCookies = 10000; // Replace with the total number of possible cookie acceptances
    const percentage = (acceptedCookieCount / totalPossibleCookies) * 100;
    setAcceptedCookiePercentage(percentage.toFixed(2));
  }, [acceptedCookieCount]);

  const fetchVisitorCount = () => {
    axios.get('https://backend.tagocash.com/visitor-count')
      .then(res => {
        setVisitorCount(res.data.count);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    fetchVisitorCount();
    // Replace the setInterval time with the interval you want to fetch the data (e.g., every 1 minute)
    const interval = setInterval(fetchVisitorCount, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Calculate the percentage
    const totalPossibleVisitors = 10000; // Replace with the total number of possible visitors
    const percentage = (visitorCount / totalPossibleVisitors) * 100;
    setVisitorPercentage(percentage.toFixed(2));
  }, [visitorCount]);

  const fetchEmployeeCount = () => {
    axios.get('https://backend.tagocash.com/employeeCount')
      .then(res => {
        setEmployeeCount(res.data[0].employee);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    fetchEmployeeCount();
    // Replace the setInterval time with the interval you want to fetch the data (e.g., every 1 minute)
    const interval = setInterval(fetchEmployeeCount, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Calculate the percentage
    const totalAuthorizedUsers = 1000; // Replace this with the total number of authorized users (you can fetch it from the server as well)
    const percentage = (employeeCount / totalAuthorizedUsers) * 100;
    setAuthorizedUsersPercentage(percentage.toFixed(2));
  }, [employeeCount]);


  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center">
        <Header title={`HELLO ${employeeRole.toUpperCase()}.`} subtitle="Welcome to your dashboard" />

      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns={{ xs: '1fr', sm: 'repeat(12, 1fr)' }}
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn={{ xs: 'span 12', sm: 'span 3' }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="0"
            subtitle="Notifications"
            progress="0.023"
            increase="+0%"
            icon={
              <EmailIcon
                sx={{ color: colors.greenAccent[600], fontSize: { xs: '20px', sm: '26px' } }}
              />
            }
          />
        </Box>
        <Box
          gridColumn={{ xs: 'span 12', sm: 'span 3' }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={acceptedCookieCount}
            subtitle="Accepted Cookie"
            progress="0.2"
            increase={`+${acceptedCookiePercentage}%`}
            icon={
              <CookieIcon
                sx={{ color: colors.greenAccent[600], fontSize: { xs: '20px', sm: '26px' } }}
              />
            }
          />
        </Box>
        <Box
          gridColumn={{ xs: 'span 12', sm: 'span 3' }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={employeeCount}
            subtitle="Authorized Users"
            progress="0.01"
            increase={`+${authorizedUsersPercentage}%`}
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: { xs: '20px', sm: '26px' } }}
              />
            }
          />
        </Box>
        <Box
          gridColumn={{ xs: 'span 12', sm: 'span 3' }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={visitorCount}
            subtitle="Page Visitors"
            progress="0.6"
            increase={`+${visitorPercentage}%`}
            icon={
              <TrafficIcon
                sx={{ color: colors.greenAccent[600], fontSize: { xs: '20px', sm: '26px' } }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn={{ xs: 'span 12', sm: 'span 8' }}
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt={{ xs: '25px', sm: '0' }}
            p={{ xs: '0 15px', sm: '0 30px' }}
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
                fontSize={{ xs: '16px', sm: '24px' }}
              >
                Device, Browser Statistics
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              ></Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: { xs: '26px', sm: '32px' }, color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m={{ xs: '-20px 0 10px 0', sm: '-20px 0 0 0' }}>
            <LineChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn={{ xs: 'span 12', sm: 'span 4' }}
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Authorized Users
            </Typography>
          </Box>
          {mockTransactions.map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                  fontSize={{ xs: '16px', sm: '20px' }}
                >
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                {transaction.cost}
              </Box>
            </Box>
          ))}
        </Box>


        {/* ROW 3 */}
        <Box
          gridColumn={{ xs: 'span 12', sm: 'span 4' }}
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Page Visitors
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              {visitorCount} Visitors in the last 28 days
            </Typography>
            <Typography>Includes page views and visitors</Typography>
          </Box>
        </Box>
        <Box
          gridColumn={{ xs: 'span 12', sm: 'span 4' }}
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p={{ xs: '20px', sm: '30px' }}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Statistics by Browsers
          </Typography>
          <Box height={{ xs: '200px', sm: '250px' }} mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn={{ xs: 'span 12', sm: 'span 4' }}
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p={{ xs: '20px', sm: '30px' }}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Geography Based Traffic
          </Typography>
          <Box height={{ xs: '150px', sm: '200px' }}>
            <GeographyChart isDashboard={true} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeeDetail