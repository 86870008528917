import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Header from "../../components/Header";
import { Base_URL } from "../../constant/Constant";
axios.defaults.withCredentials = true;

const Subscribers = () => {


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${Base_URL}/subscribers`).then((res) => {
      if (res.data.Status === 'Success') {
        const userRole = res.data.role;
        const id = res.data.id;

        if (userRole === 'superadmin' || userRole === 'admin') {
          // User is superadmin, navigate to the root ("/") route
          navigate('/subscribers');
        } else {
          // If user is not superadmin, redirect to login
          navigate('/unauthorized');
        }
      } else {
        // Redirect to login if not authenticated or not authorized
        navigate('/login');
      }
    });
  }, [navigate]);


  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${Base_URL}/getSubscribers`)
      .then(res => {
        if (res.data.Status === "Success") {
          setData(res.data.Result);
        } else {
          console.log("Error");
        }
      })
      .catch(err => console.log(err));
  }, []);



  const columns = [
    { field: "id", headerName: "ID", flex: 0.2 },
    {
      field: "firstname",
      headerName: "First Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    // {
    //   field: "middlename",
    //   headerName: "Middle Name",
    //   flex: 1,
    //   cellClassName: "name-column--cell",
    // },
    {
      field: "lastname",
      headerName: "Last Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 0.7,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 0.8,
    },
    
  ];

  return (
    <Box m="20px">
      <Header title="SUBSCRIBERS" subtitle="Users who have Subscribed" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={data.map(subscribers => ({
            id: subscribers.id,
            firstname: subscribers.firstname,
            middlename: subscribers.middlename ,
            lastname: subscribers.lastname,
            email: subscribers.email,
            country: subscribers.country,
            phone: subscribers.phone
          }))}
          columns={columns}
        />
      </Box>
    </Box>
  );
};


export default Subscribers;