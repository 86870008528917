import React, { useEffect, useState } from 'react';
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import axios from 'axios';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Base_URL } from '../../constant/Constant';
axios.defaults.withCredentials = true;

const Cookies = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "ip", headerName: "IP Address", flex: 1 },
    { field: "country", headerName: "Country", flex: 1 },
    { field: "city", headerName: "City", flex: 1 },
    { field: "latitude", headerName: "Latitude", flex: 1 },
    { field: "longitude", headerName: "Longitude", flex: 1 },
    { field: "browser", headerName: "Browser", flex: 1 },
    { field: "device", headerName: "Device", flex: 1 },
    {
      field: "timestamp",
      headerName: "Timestamp",
      flex: 1,
      valueGetter: (params) => {
        // Check if the timestamp is valid before formatting
        const timestamp = new Date(params.row.timestamp);
        return isNaN(timestamp) ? "Invalid Date" : timestamp.toLocaleString();
      },
    },
  ];
  useEffect(() => {
    axios.get(`${Base_URL}/visitors`).then((res) => {
      if (res.data.Status === 'Success') {
        if (res.data.role === 'superadmin' || res.data.role === 'admin') {
          navigate('/visitors');
        } else {
          // Redirect others to a page with a "You are not authorized" message
          navigate('/unauthorized');
        }
      } else {
        navigate('/login');
      }
    });
  }, [navigate]);

  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    axios
      .get(`${Base_URL}/getVisitors`) // Replace with your backend route
      .then((response) => {
        setContacts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching contacts:", error);
      });
  }, []);


  return (
    <Box m="20px">
      <Header
        title="VISITORS INFO"
        subtitle="List of Page Visitors"
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={contacts.map(visitors => ({
            id: visitors.id,
            ip: visitors.ip,
            country: visitors.country,
            city: visitors.city,
            latitude: visitors.latitude,
            longitude: visitors.longitude,
            browser: visitors.browser,
            device: visitors.device,
            timestamp: new Date(visitors.timestamp).toLocaleString(),
          }))}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Cookies;
