import {
  Box,
  Button,
  IconButton,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { Base_URL } from "../../constant/Constant";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [isProfileOpen, setProfileOpen] = useState(false); // State to manage profile dropdown visibility
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Function to handle search
  const handleSearch = () => {
    setLoading(true);
    fetch(`${Base_URL}/api/search?q=${searchTerm}`)
      .then((response) => response.json())
      .then((data) => {
        setSearchResults(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  // Function to handle profile icon click
  const handleProfileClick = () => {
    setProfileOpen(!isProfileOpen);
  };

  // Function to handle logout
  const handleLogout = () => {
    axios.get(`${Base_URL}/logout`)
      .then(res => {
        navigate('/login')
      }).catch(err => console.log(err));
  }

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={theme.palette.primary[400]}
        borderRadius="3px"
      >
        <InputBase
          sx={{ ml: 2, flex: 1 }}
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <IconButton
          type="button"
          sx={{ p: 1 }}
          onClick={handleSearch}
          disabled={loading}
        >
          <SearchIcon />
        </IconButton>
      </Box>

      {/* Display search results */}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {searchResults.map((result) => (
            <div key={result.table}>
              <h3>Results from {result.table} table:</h3>
              <ul>
                {result.results.map((item) => (
                  <li key={item.id}>{/* Display your search results here */}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton onClick={handleProfileClick}>
          <PersonOutlinedIcon />
        </IconButton>

        {/* Profile Dropdown */}
        {isProfileOpen && (
          <div
            style={{
              position: "absolute",
              top: "48px", // Adjust the top position as needed
              right: "8px", // Adjust the right position as needed
              backgroundColor: "#fff", // Dropdown background color
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Dropdown box shadow
              borderRadius: "4px", // Dropdown border radius
              zIndex: 1, // Ensure the dropdown appears above other content
              padding: "16px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                background: "#23A6F0",
                color: "white",
                "&:hover": {
                  background: "#23A6F0",
                },
              }}
              onClick={handleLogout}
            >
              Logout
            </Button>
            {/* Add other profile options here */}
          </div>
        )}
      </Box>
    </Box>
  );
};

export default Topbar;
