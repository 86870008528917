import React, { useEffect, useState } from 'react';
import { Box } from "@mui/material";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Base_URL } from '../../constant/Constant';
axios.defaults.withCredentials = true;

const Line = () => {

  const navigate = useNavigate();
  useEffect(() => {
    axios.get(`${Base_URL}/line`).then((res) => {
      if (res.data.Status === 'Success') {
        if (res.data.role === 'superadmin' || res.data.role === 'marketer' || res.data.role === 'admin') {
          navigate('/line');
        } else {
          // Redirect others to a page with a "You are not authorized" message
          navigate('/unauthorized');
        }
      } else {
        navigate('/login');
      }
    });
  }, [navigate]);
  return (
    <Box m="20px">
      <Header title="Line Chart" subtitle="Simple Line Chart" />
      <Box height="75vh">
        <LineChart />
      </Box>
    </Box>
  );
};

export default Line;
