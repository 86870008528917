import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import { Box, Button, Grid, Typography } from "@mui/material";
import moment from "moment";
import { Base_URL } from "../../constant/Constant";
axios.defaults.withCredentials = true;

function Blogger() {
  const [post, setPost] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    axios.get(`${Base_URL}/blogger`).then((res) => {
      if (res.data.Status === "Success") {
        if (res.data.role === "blogger" || "superadmin") {
          const id = res.data.id;
          navigate("/blogger/" + id);
        } else {
          // Redirect others to a page with a "You are not authorized" message
          navigate("/unauthorized");
        }
      } else {
        navigate("/login");
      }
    });
  }, [navigate]);

  const [posts, setPosts] = useState([]);

  const cat = useLocation().search;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${Base_URL}/api/posts`, {
          params: { cat: cat }, // Include the category as a query parameter if needed
        });
        // Sort the posts based on the date in descending order (latest first)
        const sortedPosts = res.data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setPosts(sortedPosts);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [cat]);

  const postId = location.pathname.split("/")[2];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${Base_URL}/api/posts/${postId}`);
        setPost(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [postId]);

  const getText = (html, maxLength = 100) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    let textContent = doc.body.textContent.trim();
    if (textContent.length > maxLength) {
      textContent = textContent.substring(0, maxLength) + "...";
    }
    return textContent;
  };

  const handleDelete = async (postIdToDelete) => {
    try {
      const response = await axios.delete(
        `${Base_URL}/api/posts/${postIdToDelete}`
      );
      console.log(response.data); // Log the response data
      // Update the posts state by filtering out the deleted post
      setPosts(posts.filter((post) => post.id !== postIdToDelete));
    } catch (err) {
      console.log("An unexpected error occurred:", err);
    }
  };

  return (
    <div style={{ margin: "0 auto", maxWidth: "90%" }}>
      {/* WRITE BUTTON */}

      <Typography variant="h5" style={{ fontSize: "3rem", marginTop: "4rem" }}>
        Write A New Blog
      </Typography>
      <Link className="link" to="/write">
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "2rem", marginBottom: "7rem", fontSize: "1rem" }}
        >
          Write New Blog
        </Button>
      </Link>

      {/* WRITE BUTTON END */}

      <Grid container spacing={3}>
        {posts.map((post) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={post.id}
            style={{ marginBottom: "6rem" }}
          >
            <Box
              border={1}
              m={1}
              p={1}
              boxShadow={3}
              style={{
                backgroundColor: "white",
                color: "black",
                padding: "1em 1em 2em",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
              }}
            >
              <img
                src={`${post.img}`}
                alt={`Blog ${post.title} Cover image`}
                style={{ width: "100%" }}
              />

              <h3 style={{ fontSize: "1.4rem" }}>{post.title}</h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "blue",
                  fontSize: "1rem",
                }}
              >
                <p>{moment(post.date).fromNow()}</p>
                <p>{post.cat}</p>
              </div>
              <p style={{ fontSize: "0.9rem", lineHeight: "1.7rem" }}>
                {getText(post.desc)}
              </p>
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: "100px", fontSize: "1rem" }}
              >
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to={`/write?edit=2`}
                  state={post}
                >
                  Edit
                </Link>
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleDelete(post.id)} // Pass the postId to handleDelete
                style={{ fontSize: "1rem" }}
              >
                Delete
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Blogger;
