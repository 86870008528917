import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Logo from './images/blue_cash.png'
import Lottie from "react-lottie";
import tagoLottie from "./images/lottiefiles/Logo TagoCash.json";
import Cookies from 'js-cookie';
import { Base_URL } from './constant/Constant';
import { onLoginSuccess } from './redux/feature/authSlice';
import { useDispatch } from 'react-redux';

axios.defaults.withCredentials = true;
function Login() {

  const [values, setValues] = useState({
    email: '',
    password: ''
  });

  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loggedInUserRole, setLoggedInUserRole] = useState('');
  const [token, setToken] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const dispatch = useDispatch()
  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(`${Base_URL}/login`, values)
      .then((res) => {
        if (res.data.Status === 'Success') {
          const userRole = res.data.role;
          const id = res.data.id;
          const receivedToken = res.data.token; // Extract the token from the response body
          // onLoginSuccess(())
          // Set the token as a cookie with specified attributes
          document.cookie = `token=${receivedToken};`;
          document.cookie = `userRole=${userRole};`;
          console.log(res.data, " res.data")

          dispatch(onLoginSuccess({
            user: res.data,
            token: receivedToken
          }))
          // Log cookie details to the console
          const cookieString = document.cookie;

          if (userRole === 'superadmin') {
            navigate('/');
          } else if (userRole === 'blogger') {
            navigate('/blogger/' + id);
          } else {
            navigate('/employeedetail/' + id);
          }
        } else {
          setError(res.data.Error);
        }
      })
      .catch((err) => console.log(err));
  };

  const globeOptions = {
    loop: true,
    autoplay: true,
    animationData: tagoLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };


  return (
    <Container maxWidth="xs" style={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2, textAlign: 'center', background: "#0A2540", lineHeight: "5rem" }}>
        {/* <img src={Logo} alt="tago_logo" width='200' style={{
          backgroundColor: 'white',
          borderRadius: '6px'
        }} /> */}
        <Lottie options={globeOptions} style={{ width: "70%", height: "60px", borderRadius: "10px", marginBottom: "10px" }} />
        <Typography variant="h2" gutterBottom style={{ color: "#23A6F0" }}>
          LOGIN
        </Typography>
        {loggedInUserRole && (
          <Typography variant="h4" color="success" gutterBottom>
            Logged in as: {loggedInUserRole}
          </Typography>
        )}
        <Typography variant="h4" color="error" gutterBottom style={{ marginBottom: "1.5em" }}>
          {error && error}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} style={{ paddingTop: "0px", lineHeight: "4.5rem" }}>
              <TextField
                type="email"
                label="Email"
                fullWidth
                variant="outlined"
                size="large"
                value={values.email}
                onChange={(e) => setValues({ ...values, email: e.target.value })}
                sx={{
                  '& .css-wied2b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
                    color: isFocused ? "white" : "white",
                  },
                  '& .css-wxl1jl-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: isFocused ? "rgba(255, 255, 255, 0.23)" : "rgba(255, 255, 255, 0.23)",
                  },
                }}
                onFocus={() => setIsFocused(true)}
              />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "0px", lineHeight: "4.5rem" }}>
              <TextField
                type="password"
                label="Password"
                fullWidth
                variant="outlined"
                size="large"
                value={values.password}
                onChange={e => setValues({ ...values, password: e.target.value })}
                sx={{
                  '& .css-wied2b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
                    color: isFocused ? "white" : "white",
                  },
                  '& .css-wxl1jl-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: isFocused ? "rgba(255, 255, 255, 0.23)" : "rgba(255, 255, 255, 0.23)",
                  },
                }}
                onFocus={() => setIsFocused(true)}
              />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "0px", lineHeight: "2rem" }}>
              <Button type="submit" variant="contained" color="primary" fullWidth
                sx={{
                  fontSize: '18px', height: '48px', borderRadius: '8px', background: "#0581F8",
                  '&:hover': {
                    background: "#0581F8",
                  },
                }}>
                Log in
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" color="textSecondary" style={{ marginTop: "0.5rem", color: "#23A6F0" }}>
                Welcome to TagoCash Dashboard.
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container >
  );
}


export default Login;
