import React, { useEffect, useState } from 'react';
import { Box, useTheme } from "@mui/material";
import GeographyChart from "../../components/GeographyChart";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Base_URL } from '../../constant/Constant';
axios.defaults.withCredentials = true;

const Geography = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  useEffect(() => {
    axios.get(`${Base_URL}/geography`).then((res) => {
      if (res.data.Status === 'Success') {
        if (res.data.role === 'superadmin' || res.data.role === 'marketer' || res.data.role === 'admin') {
          navigate('/geography');
        } else {
          // Redirect others to a page with a "You are not authorized" message
          navigate('/unauthorized');
        }
      } else {
        navigate('/login');
      }
    });
  }, [navigate]);
  return (
    <Box m="20px">
      <Header title="Geography" subtitle="Simple Geography Chart" />
      <Box
        height="75vh"
        border={`1px solid ${colors.grey[100]}`}
        borderRadius="4px"
      >
        <GeographyChart />
      </Box>
    </Box>
  );
};

export default Geography;