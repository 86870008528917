import {
    Box,
    Button,
    Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from 'react-toastify';
import { Base_URL } from "../../../constant/Constant";

const SignUp = () => {

    var toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ];

    const module = {
        toolbar: toolbarOptions,
    };

    const isNonMobile = useMediaQuery("(min-width:600px)");
    const notify = () => toast.success('Successfully updated the website!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    const [book, setBook] = useState({
        title: "",
        title2: "",
        title3: "",
        title4: "",
        title5: "",
        desc: "",
        desc1: "",
        desc2: "",
        desc3: "",
        desc4: "",
        desc5: "",
        par: "",
        par1: "",
        par2: "",
        par3: "",
        par4: "",
        par5: "",
        par6: "",
        par7: "",
        par8: "",
        par9: "",
        par10: "",
    });


    const [error, setError] = useState(false);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const location = useLocation();
    const navigate = useNavigate();

    const bookId = location.pathname.split("/")[2];

    const handleChange = (e) => {
        setBook((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };


    const getUserRole = () => {
        return "superadmin"; // Replace with actual role value
    };

    const handleClick = async (e) => {
        e.preventDefault();

        try {
            const userRole = getUserRole();
            let updateEndpoint = `/signup_page_pending/${bookId}`; // Default to pending updates
            let data = {
                title: book.title,
                title2: book.title2,
                title3: book.title3,
                title4: book.title4,
                title5: book.title5,
                desc: book.desc,
                desc1: book.desc1,
                desc2: book.desc2,
                desc3: book.desc3,
                desc4: book.desc4,
                desc5: book.desc5,
                par: book.par,
                par1: book.par1,
                par2: book.par2,
                par3: book.par3,
                par4: book.par4,
                par5: book.par5,
                par6: book.par6,
                par7: book.par7,
                par8: book.par8,
                par9: book.par9,
                par10: book.par10,
                status: null // Default status is null
            };

            if (userRole === "superadmin" || userRole === "admin") {
                const status = "approved";
                data.status = status; // Update status for admin users
                updateEndpoint = `/signup_page/${bookId}`; // Use the direct update endpoint
            }

            const response = await axios.put(`${Base_URL}${updateEndpoint}`, data);
            console.log(response.data, "data----->");
            navigate("/edit");
        } catch (err) {
            console.error(err);
            setError(true);
        }
    };


    useEffect(() => {
        axios
            .get(`${Base_URL}/signup_page/${bookId}`)

            .then((response) => {
                const existingBook = response.data[0];
                setBook(existingBook);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [bookId]);

    return (
        <Box m="20px" sx={{
            "& > div": {
                paddingBottom: "30px",
            },
        }}>
            <Header title="UPDATE WEBSITE" />

            {/* SIGN UP PAGE SECTION START */}
            <Box
                display="grid"
                gap="25px"
                gridTemplateColumns="repeat(1, minmax(0, 1fr))"

                sx={{
                    "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 1",
                        marginBottom: "30px",
                    },
                }}
            >

                <Header title="Sign Up page"
                    subtitle="Make it captivating as possible." />
            </Box>
            <Box
                display="grid"
                gap={isMobile ? "70px" : "25px"}
                gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                sx={{
                    "& > div": {
                        gridColumn: isMobile ? "span 1" : undefined,
                        marginBottom: isMobile ? "60px" : "40px",
                    },
                }}
            >
                <ReactQuill
                    theme="snow"
                    value={book.title}
                    modules={module}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, title: value }))
                    }
                />
                <ReactQuill
                    value={book.desc}
                    modules={module}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, desc: value }))
                    }
                />

            </Box>
            {/* SIGN UP PAGE SECTION END */}

            {/* CARD SECTION START */}
            <Box
                display="grid"
                gap="25px"
                gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                sx={{
                    "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 1",
                        marginBottom: "35px",
                        marginTop: "35px",
                    },
                }}
            >

                <Header title="
                Tago Fan Card Section"
                    subtitle="Update the Tago Fan Card" />
            </Box>

            <Box
                display="grid"
                gap={isMobile ? "70px" : "25px"}
                gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                sx={{
                    "& > div": {
                        gridColumn: isMobile ? "span 1" : undefined,
                        marginBottom: isMobile ? "60px" : "40px",
                    },
                }}
            >
                <ReactQuill
                    value={book.title2}
                    modules={module}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, title2: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc1}
                    onChange={(value) => setBook((prev) => ({ ...prev, desc1: value }))}
                />
                <ReactQuill
                    modules={module}
                    value={book.title3}
                    onChange={(value) => setBook((prev) => ({ ...prev, title3: value }))}
                />
                <ReactQuill
                    modules={module}
                    value={book.desc2}
                    onChange={(value) => setBook((prev) => ({ ...prev, desc2: value }))}
                />
                <ReactQuill
                    modules={module}
                    value={book.par}
                    onChange={(value) => setBook((prev) => ({ ...prev, par: value }))}
                />
                <ReactQuill
                    modules={module}
                    value={book.par1}
                    onChange={(value) => setBook((prev) => ({ ...prev, par1: value }))}
                />
                <ReactQuill
                    modules={module}
                    value={book.par2}
                    onChange={(value) => setBook((prev) => ({ ...prev, par2: value }))}
                />

                <ReactQuill
                    modules={module}
                    value={book.par3}
                    onChange={(value) => setBook((prev) => ({ ...prev, par3: value }))}
                />
                <ReactQuill
                    modules={module}
                    value={book.par4}
                    onChange={(value) => setBook((prev) => ({ ...prev, par4: value }))}
                />
                <ReactQuill
                    modules={module}
                    value={book.desc3}
                    onChange={(value) => setBook((prev) => ({ ...prev, desc3: value }))}
                />

            </Box>

            {/* CARD SECTION END */}

            {/* PLAN SECTION START */}
            <Box
                display="grid"
                gap="25px"
                gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                sx={{
                    "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 1",
                        marginBottom: "35px",
                        marginTop: "35px",
                    },
                }}
            >

                <Header title="Tago Hero Card Section"
                    subtitle="Update the tago hero section" />
            </Box>

            <Box
                display="grid"
                gap={isMobile ? "70px" : "25px"}
                gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                sx={{
                    "& > div": {
                        gridColumn: isMobile ? "span 1" : undefined,
                        marginBottom: isMobile ? "60px" : "40px",
                    },
                }}
            >
                <ReactQuill
                    modules={module}
                    value={book.title4}
                    onChange={(value) =>
                        setBook((prev) => ({ ...prev, title4: value }))
                    }
                />
                <ReactQuill
                    modules={module}
                    value={book.desc4}
                    onChange={(value) => setBook((prev) => ({ ...prev, desc4: value }))}
                />
                <ReactQuill
                    modules={module}
                    value={book.title5}
                    onChange={(value) => setBook((prev) => ({ ...prev, title5: value }))}
                />
                <ReactQuill
                    modules={module}
                    value={book.desc5}
                    onChange={(value) => setBook((prev) => ({ ...prev, desc5: value }))}
                />
                <ReactQuill
                    modules={module}
                    value={book.par5}
                    onChange={(value) => setBook((prev) => ({ ...prev, par5: value }))}
                />
                <ReactQuill
                    modules={module}
                    value={book.par6}
                    onChange={(value) => setBook((prev) => ({ ...prev, par6: value }))}
                />
                <ReactQuill
                    modules={module}
                    value={book.par7}
                    onChange={(value) => setBook((prev) => ({ ...prev, par7: value }))}
                />
                <ReactQuill
                    modules={module}
                    value={book.par8}
                    onChange={(value) => setBook((prev) => ({ ...prev, par8: value }))}
                />
                <ReactQuill
                    modules={module}
                    value={book.par9}
                    onChange={(value) => setBook((prev) => ({ ...prev, par9: value }))}
                />
                <ReactQuill
                    modules={module}
                    value={book.par10}
                    onChange={(value) => setBook((prev) => ({ ...prev, par10: value }))}
                />

            </Box>

            <Box my={2} style={{
                marginTop: '50px'
            }}>
                <Button variant="contained" color="secondary" onClick={(e) => { notify(); handleClick(e); }}>
                    Update
                </Button>
            </Box>
            {error && <Typography color="error">Something went wrong!</Typography>}
        </Box>
    );
};

export default SignUp;
