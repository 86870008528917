import React, { useEffect, useState } from 'react';
import { Box } from "@mui/material";
import Header from "../../components/Header";
import PieChart from "../../components/PieChart";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Base_URL } from '../../constant/Constant';
axios.defaults.withCredentials = true;

const Pie = () => {

  const navigate = useNavigate();
  useEffect(() => {
    axios.get(`${Base_URL}/pie`).then((res) => {
      if (res.data.Status === 'Success') {
        if (res.data.role === 'superadmin' || res.data.role === 'marketer' || res.data.role === 'admin') {
          navigate('/pie');
        } else {
          // Redirect others to a page with a "You are not authorized" message
          navigate('/unauthorized');
        }
      } else {
        navigate('/login');
      }
    });
  }, [navigate]);
  return (
    <Box m="20px">
      <Header title="Pie Chart" subtitle="Browsers' Pie Chart" />
      <Box height="75vh">
        <PieChart />
      </Box>
    </Box>
  );
};

export default Pie;
