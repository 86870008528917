import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Header from '../src/components/Header';
import { Base_URL } from './constant/Constant';

function EditEmployee() {
  const [data, setData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    country: '',
    city: '',
    streetaddress: '',
    zipcode: '',
    role: '',
  });

  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id, "employeeid")

  useEffect(() => {
    axios
      .get(`${Base_URL}/get/` + id)
      .then((res) => {
      })
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    axios.get(`${Base_URL}/getEmployee/` + id)
      .then(res => {
        if (res.data.Status === "Success") {
          setData({
            firstname: res.data.Result[0].firstname,
            lastname: res.data.Result[0].lastname,
            email: res.data.Result[0].email,
            country: res.data.Result[0].country,
            city: res.data.Result[0].city,
            streetaddress: res.data.Result[0].streetaddress,
            zipcode: res.data.Result[0].zipcode,
            role: res.data.Result[0].role, 
          });
          console.log(res, ">>>>>")
        } else {
          console.log("Error");
        }
        console.log(res, ">>>>>>>>>")
      })
      .catch(err => console.log(err));
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .put(`${Base_URL}/update/` + id, data)
      .then((res) => {
        if (res.data.Status === 'Success') {
          navigate('/employees');
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" pt={4}>
      <Header title="Update Employee" />
      <form onSubmit={handleSubmit} style={{ width: '50%' }}>
        <TextField
          fullWidth
          variant="outlined"
          name="firstname"
          label="First Name"
          placeholder="Enter First Name"
          autoComplete="off"
          value={data.firstname}
          onChange={(e) => setData({ ...data, firstname: e.target.value })}
          margin="normal"
          sx={{
            '& .css-wied2b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
              color: "white",
            },
            '& .css-wxl1jl-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: "rgba(255, 255, 255, 0.23)",
            },
          }}
        />

        <TextField
          fullWidth
          variant="outlined"
          name="lastname"
          label="Last Name"
          placeholder="Enter Last Name"
          autoComplete="off"
          value={data.lastname}
          onChange={(e) => setData({ ...data, lastname: e.target.value })}
          margin="normal"
          sx={{
            '& .css-wied2b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
              color: "white",
            },
            '& .css-wxl1jl-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: "rgba(255, 255, 255, 0.23)",
            },
          }}
        />

        <TextField
          fullWidth
          variant="outlined"
          name="email"
          label="Email"
          placeholder="Enter Email"
          autoComplete="off"
          value={data.email}
          onChange={(e) => setData({ ...data, email: e.target.value })}
          margin="normal"
          sx={{
            '& .css-wied2b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
              color: "white",
            },
            '& .css-wxl1jl-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: "rgba(255, 255, 255, 0.23)",
            },
          }}
        />

        <TextField
          fullWidth
          variant="outlined"
          name="country"
          label="Country"
          placeholder="Enter Country"
          autoComplete="off"
          value={data.country}
          onChange={(e) => setData({ ...data, country: e.target.value })}
          margin="normal"
          sx={{
            '& .css-wied2b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
              color: "white",
            },
            '& .css-wxl1jl-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: "rgba(255, 255, 255, 0.23)",
            },
          }}
        />

        <TextField
          fullWidth
          variant="outlined"
          name="city"
          label="City"
          placeholder="Enter City"
          autoComplete="off"
          value={data.city}
          onChange={(e) => setData({ ...data, city: e.target.value })}
          margin="normal"
          sx={{
            '& .css-wied2b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
              color: "white",
            },
            '& .css-wxl1jl-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: "rgba(255, 255, 255, 0.23)",
            },
          }}
        />

        <TextField
          fullWidth
          variant="outlined"
          name="streetaddress"
          label="Street Address"
          placeholder="Enter Street Address"
          autoComplete="off"
          value={data.streetaddress}
          onChange={(e) => setData({ ...data, streetaddress: e.target.value })}
          margin="normal"
          sx={{
            '& .css-wied2b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
              color: "white",
            },
            '& .css-wxl1jl-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: "rgba(255, 255, 255, 0.23)",
            },
          }}
        />

        <TextField
          fullWidth
          variant="outlined"
          name="zipcode"
          label="Zip Code"
          placeholder="Enter Zip Code"
          autoComplete="off"
          value={data.zipcode}
          onChange={(e) => setData({ ...data, zipcode: e.target.value })}
          margin="normal"
          sx={{
            '& .css-wied2b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
              color: "white",
            },
            '& .css-wxl1jl-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: "rgba(255, 255, 255, 0.23)",
            },
          }}
        />
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel>Access Level</InputLabel>
          <Select
            name="role"
            value={data.role}
            onChange={(e) => setData({ ...data, role: e.target.value })}
            label="Access Level"
            sx={{
              '& .css-wied2b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
                color: "white",
              },
              '& .css-wxl1jl-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "rgba(255, 255, 255, 0.23)",
              },
            }}
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="marketer">Marketer</MenuItem>
            <MenuItem value="Tech Staff">Tech Staff</MenuItem>
            <MenuItem value="blogger">Blogger</MenuItem>
          </Select>

        </FormControl>

        <Box mt={2}>
          <Button type="submit" variant="contained" style={{ background: "#23A6F0" }}>
            Update
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default EditEmployee;