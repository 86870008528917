import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Header from "../../components/Header";
import { toast } from 'react-toastify';
import { Base_URL } from "../../constant/Constant";
axios.defaults.withCredentials = true;

const Employees = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const notify = () => toast.warn('You Deleted an Employee!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  useEffect(() => {
    axios.get(`${Base_URL}/employees`).then((res) => {
      if (res.data.Status === 'Success') {
        const userRole = res.data.role;
        const id = res.data.id;

        if (userRole === 'superadmin') {
          // User is superadmin, navigate to the root ("/") route
          navigate('/employees');
        } else {
          // If user is not superadmin, redirect to login
          navigate('/unauthorized');
        }
      } else {
        // Redirect to login if not authenticated or not authorized
        navigate('/login');
      }
    });
  }, [navigate]);


  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${Base_URL}/getEmployee`)
      .then(res => {
        if (res.data.Status === "Success") {
          setData(res.data.Result);
        } else {
          console.log("Error");
        }
      })
      .catch(err => console.log(err));
  }, []);


  const handleDelete = (id) => {
    axios.delete(`${Base_URL}/delete/` + id)
      .then(res => {
        if (res.data.Status === "Success") {
          // Remove the deleted employee from the data state
          setData(prevData => prevData.filter(employee => employee.id !== id));
        } else {
          alert("Error deleting employee");
        }
      })
      .catch(err => {
        console.log(err);
        alert("Error deleting employee");
      });
  };


  const columns = [
    { field: "id", headerName: "ID", flex: 0.2 },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 0.7,
    },
    {
      field: "city",
      headerName: "City",
      flex: 0.7,
    },
    {
      field: "streetaddress",
      headerName: "Street Address",
      flex: 0.7,
    },
    {
      field: "zipcode",
      headerName: "Zip Code",
      flex: 0.4,
    },
    {
      field: "role",
      headerName: "Access Level",
      flex: 0.9,
      renderCell: ({ row: { role } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              role === "admin"
                ? colors.greenAccent[600]
                : role === "marketer"
                  ? colors.greenAccent[700]
                  : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {role === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {role === "marketer" && <SecurityOutlinedIcon />}
            {role === "Tech Staff" && <LockOpenOutlinedIcon />}
            {role === "blogger" && <LockOpenOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {role}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Link to={`/employeeEdit/${row.id}`} >
            <Button variant="contained" color="primary" startIcon={<EditIcon />}>
              Edit
            </Button>
          </Link>
          <Button
            onClick={() => {
              notify();
              handleDelete(row.id);
            }}
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>

        </>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="EMPLOYEES" subtitle="Managing the Employees" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={data.map(employee => ({
            id: employee.id,
            name: employee.firstname + " " + employee.lastname,
            email: employee.email,
            country: employee.country,
            city: employee.city,
            streetaddress: employee.streetaddress,
            zipcode: employee.zipcode,
            role: employee.role
          }))}
          columns={columns}
        />
      </Box>
    </Box>
  );
};


export default Employees;